import { Link } from 'gatsby';
import React from 'react';

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>The Mission</h2>
      <p>
        The team behind Place.Earth are on a mission to leverage their combined
        skills and experience to make the world work better for everyone.
        Solving population-level crisis communications is just one of the many
        projects in the pipeline.
      </p>
    </section>
    <section>
      <h2>Contact Details</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>PO Box 664 &bull; Balmain, NSW &bull; Australia</dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:enquiries@place.earth">enquiries@place.earth</a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a
            href="https://twitter.com/placeearthsoft"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/place.earth"
            className="icon fa-facebook alt"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/placeearth/"
            className="icon fa-linkedin alt"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      &copy;{' '}
      <a href="https://busitect.com/" target={'_blank'}>
        BUSITECT 2022
      </a>{' '}
      | DESIGN <a href="https://html5up.net">HTML5 UP</a>
    </p>
  </footer>
);

export default Footer;
